import React from "react";
import { graphql, Link } from "gatsby";
import { truncateBody } from "../utils";
import { Header, Footer } from "../components/layout/index";
import { BannerImage } from "../components/atoms/BannerImage";
import { Seo } from "../components/Seo";
import { blogPath } from "../constants";
import { t } from "../translations";
import Image from "gatsby-plugin-sanity-image";

const Blog = ({ data, pageContext }) => {
  if (!data) return null;
  const { locale } = pageContext;
  const blog_posts = data.blog_posts.edges.map(edge => edge.node);
  return (
    <>
      <Seo locale={locale} title={t(locale, "news.seo-title")} />
      <Header locale={locale} />
      <div className="text-center my-16 max-w-xl lg:max-w-none mx-auto">
        <h1 className="text-3xl tracking-tight font-bold text-gray-900 sm:text-4xl">
          {t(locale, "news.title")}
        </h1>
      </div>

      <div className="max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-7xl">
        {blog_posts.map(
          ({
            id,
            locale,
            mainImage,
            publishedAt,
            slug,
            title,
            body,
            description,
          }) => {
            const excerpt = truncateBody(body, 220);

            return (
              <div
                className="flex flex-col rounded-lg shadow-lg overflow-hidden"
                key={id}
              >
                <Link
                  to={blogPath({ locale, slug: slug.current })}
                  className="h-full"
                >
                  <div className="flex-shrink-0">
                    <Image
                      {...mainImage}
                      className="h-48 w-full object-cover"
                      width={600}
                    />
                  </div>
                  <div className="flex-1 bg-white p-8 flex flex-col justify-between">
                    <div className="flex-1">
                      <p className="text-sm text-gray-500">
                        <time dateTime={publishedAt}>{publishedAt}</time>
                      </p>
                      <div className="block mt-3">
                        <h2 className="text-xl font-semibold text-gray-900">
                          {title}
                        </h2>
                        <p className="mt-3 text-base text-gray-500">
                          {description || excerpt}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            );
          },
        )}
      </div>
      <BannerImage locale={locale} />
      <Footer locale={locale} />
    </>
  );
};

export const pageQuery = graphql`
  query BlogListingByLocale($locale: String) {
    defaultCover: file(
      relativePath: { regex: "/default_article_image.jpg/gm" }
    ) {
      publicURL
    }
    blog_posts: allSanityBlog(
      filter: { locale: { eq: $locale } }
      sort: { fields: publishedAt, order: DESC }
    ) {
      edges {
        node {
          id
          locale
          title
          slug {
            current
          }
          body: _rawBody
          description
          publishedAt(formatString: "DD MMMM YYYY", locale: "fr")
          mainImage {
            ...ImageWithPreview
            alt
          }
        }
      }
    }
  }
`;

export default Blog;
